const contents = {
  Flowers_For_Everyone_From_Nowhere: {
    name: "Flowers For Everyone From Nowhere",
    tool: ["p5js","GLSL"],
    year: 202404,
    tag: ["interactions","music for all", "web"],
    genre: ["concert","web", "media art"],
    vid:"https://www.youtube.com/embed/yj0oaFK7vEY?si=AwvsN7ytFw0ZvOAU&amp;start=375",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Flowers%20For%20Everyone%20From%20Nowhere%2Fthumbnail.jpg?alt=media&token=9022ebfa-37f6-4b98-a4e2-0a99e5dda5cb",
      gif: "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Flowers%20For%20Everyone%20From%20Nowhere%2Fthumbnail.gif?alt=media&token=28057269-4431-4892-8c81-68fb975384d4",
    },
  },
  Korean_Fir: {
    name: "Korean Fir",
    tool: ["tone.js"],
    year: 202404,
    tag: ["music with code"],
    genre: ["music","web"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Korean%20Fir%2Fthumbnail.jpg?alt=media&token=0e39642a-5824-42bf-bb1a-03e95d2ca2db",
      gif: "",
    },
  },
  Asteroid_Destruction: {
    name: "Asteroid Destruction!",
    tool: ["Unity", "puredata"],
    year: 202312,
    tag: ["music with code", "interactions", "music for all"],
    genre: ["game", "music instrument"],
    vid: "https://www.youtube.com/embed/uqrvkWGLdIg?si=QQhwEpzYMNHbL0GK",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Asteroid%20Destruction%2Fthumbnail.jpg?alt=media&token=d6fc111a-d47c-4e11-ac09-10603732ace8",
      gif: "",
    },
  },
  Sound_Park_Emotion_Dump: {
    name: "Sound Park Emotion Dump",
    tool: ["Unity", "MAX/MSP", "socket.io"],
    year: 202312,
    tag: ["music with code", "interactions", "music for all"],
    genre: ["game", "music instrument", "media art"],
    vid: "https://www.youtube.com/embed/Wz-jpPe5njk?si=xKPcxgAphFI-ETGP",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Sound%20Park%20Emotion%20Dump%2Fthumbnail.jpg?alt=media&token=8577ed58-6c68-4810-a50c-b89d0cde5986",
      gif: "",
    },
  },
  D_Minor_of_6_Colors: {
    name: "D Minor of 6 Colors",
    tool: ["p5.js", "react", "cubase"],
    year: 202206,
    tag: ["music with code", "web development", "interactions"],
    genre: ["web", "concert", "media art"],
    vid: "https://www.youtube.com/embed/qvkFULoLdzI",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/D%20Minor%20of%206%20Colors%2Fthumbnail.jpg?alt=media&token=5ed3594f-ba18-4ff3-a3a8-e0944faf7895",
      gif: "",
    },
    contents: (
      <div>
        <p>
          "D Minor of 6 Colors" is a live talk show hosted online and offline
          through zoom. As an adaptation of the novel "A Horse Walks into a Bar"
          by David Grossman, the show goes as a stand-up comedy, which is full
          of self-deprecation and satire against hatred. The show talks about
          the hatred against queers by displaying the hate speech shown in SNS
          and sharing the related experience of the speaker.
        </p>
        <p>
          The show consists of 3 D-minor songs, generative visuals, and the
          speaker's talk. After receiving the [invitation
          card](https://jade570.github.io/invitation/), the audience may join
          the show through zoom or come to the concert location. The invitation
          card also contains generative visuals and the performer's recorded
          voice.
        </p>
        <p>
          The concert has three themes - setting the relationship between the
          performer and the audience, the funeral of the queers, and the
          performer's personal experience as a suicide survivor. As the theme
          changes, there are changes in the song and the visual. The performer
          can play some melodies on top of the music while talking. As the show
          talks about personal experience, the music builds the core atmosphere
          of the experience. Some impromptus performed in real-time explains the
          performer's feeling as they pull their grief memory out.
        </p>
        <p>
          The transgender pride flag color in the invitation is a metaphor for
          queers. In the card, lots of circles make trails, which means each
          person makes their trail of life. As the streams dim, the gray
          background appears as the rain falls. Since the concert shows the life
          and death of queers, the invitation tries to show this - the bright
          pride and grief reality of queers. It also shows how the concert will
          go - the first theme is bright and full of self-deprecation. However,
          as the theme changes, it approaches the darkest memories of the
          performer.
        </p>
        <p>
          As the invitation used the colors of the pride flag, the first theme
          uses the queer pride flag color code for the metaphor of the
          brightness and hopes of queers. The self-deprecation and jokes about
          queers are not just a comedy but also reflect reality and show how
          society can improve. With the delightful background and the
          conflicting talks, the audience gets the jokes less seriously but
          rather fun, as the original novel was a stand-up comedy.
        </p>
        <p>
          As it moves to the second theme, the display shows the curses about
          queers shared on the internet. The performer talks about their
          experience of being denied their queer identity, amatonormativity, and
          the funeral of the queers.
        </p>
        <p>
          The third theme tells about the performer's personal experience, their
          close friend's suicide, and his funeral. They talk about how bad it
          influenced them, how they felt, how their heart collapsed and how hard
          they could surmount.
        </p>
        <p>
          With this theme, the performer brings the show as their original
          concert from the adaptation of the novel. It works as the performer's
          confession and the chance to reveal their colossal wound in front of
          others and start to heal.
        </p>
        <p>
          With the collaboration of all jokes, stories, visuals, songs, and
          witty impromptus, the audience immerses into the story of the queers
          and the performer. As the awareness about queers is low in Korea, the
          audience rethinks about queers and the hatred against them. Since the
          suicide of close people is a tremendous shock among people, the
          audience empathizes with the performer and shares the agony.
        </p>
        <h2>Links</h2>
        <ul>
          <li>
            <a href="https://jade570.github.io/invitation/">Invitation Card</a>
          </li>
          <li>
            <a href="https://jade570.github.io/visualstory_final/">Visuals</a>
          </li>
          <li>
            <a href="https://pond-flax-947.notion.site/9c01c90b06f14fddaa87007f94e1e323">
              Scripts
            </a>
          </li>
          <li>
            <a href="https://soundcloud.com/jade570/sets/d-minor-of-6-colors-backing-track?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing">
              Songs
            </a>
          </li>
        </ul>
      </div>
    ),
  },

  AI_Knows_The_Answer: {
    name: "AI Knows The Answer",
    tool: ["react", "cubase", "AWS"],
    year: 202204,
    tag: ["music with code", "interactions", "web development"],
    genre: ["web", "game"],
    vid: ["https://www.youtube.com/embed/VkWZuY5Eh7o"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/AI%20Knows%20The%20Answer%2Fthumbnail.jpg?alt=media&token=5e92197b-f269-469d-9271-ce75616f6a4f",
      gif: "",
    },
    contents: (
      <div>
        <p>
          "AI Knows The Answer" is a midterm project of Visual Story @ Art &
          Technology, Sogang University. It is an adaptation of the play
          "Frankenstein" by Nick Dear. The game is about the investigator who
          solves a crime case with the aid of AI. It is an adaptation of "the
          creature" in the original play. Through individual crime cases, the
          player not only dives into the world the game shows but also discovers
          various discriminations latent within us.
        </p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/AI%20Knows%20The%20Answer%2F1.gif?alt=media&token=4f35ff07-120d-433d-a0cb-1475c7718a0d"
          alt="prologue scene of 'AI Knows The Answer'"
        ></img>
        <p>
          As you go through the prologue, you will see the background. Due to
          the grave climate crisis, numerous things are different in the future.
          With short animations and music, you will lightly get into this world.{" "}
        </p>
        <p>
          The main game goes with the given form - AI's case briefing, finding
          clues from the CCTV videos, a talk about the case with the AI, and the
          abstract of the case. You will solve crime cases for four days. As you
          go through, you will see how AI changes and how better it helps you.
          You will also find out where each case leads you.
        </p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/AI%20Knows%20The%20Answer%2F2.gif?alt=media&token=3fc603e9-f164-4876-8d9b-bc2a86876982"
          alt="CCTV video playing"
        ></img>
        <p>
          The interaction-game part involves the role of finding clues from the
          CCTV. The player must discover some strange points from the given
          short video. The player can play/pause/stop the recording or move to a
          specific frame.
        </p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/AI%20Knows%20The%20Answer%2F3.gif?alt=media&token=7c38fa7d-c26e-4744-a60c-92c2273960f9"
          alt="finding clues with AI's suggestion"
        ></img>
        <p>
          As the player catches the odd thing and clicks it, the AI's suggestion
          for the clue pops out. With the AI's aid, you will figure out the
          detail. Each case has two clues to find out, and after you
          successfully find two clues, then the continue button activates.
        </p>
        <p>-- spoiler alert! --</p>
        <p>
          After four days of investigation, you will see the ending. The game
          starts with the seriously biased AI, and you succeeded in improving it
          and leading it to social justice. You found and wiped out a big
          cartel. However, there awaits a hidden story.
        </p>
        <p>
          You thought you had changed the AI, and everything was going fine.
          However, is it the right way for someone to change? Isn't telling
          someone to change just a lazy attempt to manipulate people? Have we
          ever thought of why someone has that opinion?
        </p>
        <p>
          Normal cases, the criminal cases from day 1 to day 4, and the normal
          ending are about the climate crisis, veganism, hate crimes, and
          gender/financial inequality. Day 5 talks about racial inequality and
          asks the player about what is the right way for people to change.
          While other cases share the same formats, day 5 adopts and twists it.
          While the others try to focus on problem-solving and showing story
          progress, day five directly shows hatred.
        </p>
        <p>
          From this game, we tried to build the world as realistically as
          possible by adjusting hatred in our society and the real problems the
          AI meets. All the while, we tried to keep the mystical atmosphere of
          the future by adding artistic sugars. You will enjoy it with an
          immersive story, unique gameplay, and fantastic artwork.
        </p>
        <br />
        <h2>Credits</h2>
        <ul>
          <li>
            Chaeryeong Oh - story polishing, story, sound, web design, web
            development
          </li>
          <li>Kisam Kim - story, illustration, animation</li>
          <li>Sol Heo - story, PM, the final video</li>
        </ul>
      </div>
    ),
  },

  Fantastic_AI_Sinawi: {
    name: "Fantastic AI Sinawi",
    tool: ["p5.js", "pytorch", "python"],
    year: 202206,
    tag: ["music with code", "interactions"],
    genre: ["MIR", "media art", "music video"],
    vid: "https://www.youtube.com/embed/MJNGXpRdKpg",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Fantastic%20AI%20Sinawi%2Fthumbnail.jpg?alt=media&token=32206640-57e0-4f2e-81c2-c5c700092163",
      gif: "",
    },
    contents: <div></div>,
  },

  doongdoong_club: {
    name: "doongdoong.club",
    tool: [
      "playcanvas",
      "tone.js",
      "socket.io",
      "cubase",
      "node.js",
      "express",
      "AWS",
    ],
    year: 202112,
    tag: [
      "music with code",
      "interactions",
      "music for all",
      "web development",
    ],
    genre: ["metaverse", "media art", "music instrument", "web"],
    vid: "https://www.youtube.com/embed/Fr2YpuKzv9w",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/doongdoong.club%2Fthumbnail.jpg?alt=media&token=d0059008-cea3-4d90-91c2-04b268bf1632",
      gif: "",
    },
  },

  Away_From: {
    name: "Away From ______.",
    tool: ["openframeworks", "arduino"],
    year: 202106,
    tag: ["interactions"],
    genre: ["media art"],
    vid: "https://www.youtube.com/embed/SeSnvXZi3tc",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Away%20From%20______.%2Fthumbnail.jpg?alt=media&token=69b4b915-bdf9-4ad8-bb74-8ed49a8b908b",
      gif: "",
    },
    contents: (
      <div>
        <p>
          "Away From ______." is a media art for COVID-19. After the COVID-19
          strike, people were agitated by it. Daily life stress was always
          there, so people had new uneasiness. Away From ______. provides small
          space to get away from stressful moments. Away from COVID-19, the
          city, and people, a participant will have a journey to a tranquil
          night beach with a small Arduino-programmed lantern.
        </p>
        <p>
          A lantern consists of a sensor and Xbee. A sensor sends X, Y, and Z
          angles and coordinates of the lantern through Xbee. Then the program
          calculates the X and Y coordinate of the media wall and projects the
          lantern light. Real LED light from the lantern and the projection
          light combines gently and makes a beautiful sight.
        </p>
        <p>
          The projection visuals project two sides - the front wall and the
          floor. On the floor, a gentle wave comes and goes like the actual tide
          of the beach. With the light-night beach shower, the participant gets
          relief without any mess.
        </p>
        <p>
          The participant will see a heaving sea and stars on the front wall.
          The wall displays different projections as the participant points
          somewhere in the front wall. Alternative objects come out by the
          coordinate. If the pointed coordinate is above the sea, the aurora
          comes out. Otherwise, the lantern light comes out.
        </p>
        <p>
          Away From ______. severs the participant from daily life and gives
          them their place to heal themselves. Like a space the title of this
          art piece has, we hope participants get some space to rest, away from
          anything that bothers people.
        </p>
        <br />
        <h2>Credits</h2>
        <ul>
          <li>Chaeryeong Oh - front&floor projection, networking</li>
          <li>Gahyun Kim - hardware development, networking, model</li>
          <li>Hanul Kim - film</li>
        </ul>
        <br />
        <h2>Featured in</h2>
        <ul>
          <li>
            <a href="https://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE11043963">
              HCI Korea 2022 Creative Award - Interactive Art Gallery
            </a>
          </li>
          <li>
            <a href="https://youtu.be/5_wuQMowRpU">
              Art & Technology Sogang 2021 Showcase
            </a>
          </li>
        </ul>
      </div>
    ),
  },

  Tonic_Scape: {
    name: "Tonic Scape",
    tool: ["unity", "puredata"],
    year: 202006,
    tag: ["interactions", "music with code", "music for all"],
    genre: ["VR", "music instrument", "game"],
    vid: "https://www.youtube.com/embed/QYP0AGHhPvs",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Tonic%20Scape%2Fthumbnail.jpg?alt=media&token=7abfeb6d-4ed5-43e7-a400-4702fd158df8",
      gif: "",
    },
  },

  Computer_Graphics_2020: {
    name: "Computer Graphics 2020",
    tool: ["tone.js", "p5.js", "puredata"],
    year: 202006,
    tag: ["interactions", "web development", "music with code"],
    genre: ["music video", "media art", "music instrument"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Computer%20Graphics%202020%2Fthumbnail.jpg?alt=media&token=afaefdcb-53c6-40e8-ac55-b75ee15aa7d9",
      gif: "",
    },
  },

  Need_For_Sound: {
    name: "Need For Sound",
    tool: ["unity", "puredata", "processing"],
    year: 201812,
    tag: ["interactions", "music with code", "music for all"],
    genre: ["game", "media art", "music instrument"],
    vid: "https://www.youtube.com/embed/8p3pd4kbmAY",
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Need%20For%20Sound%2Fthumbnail.jpg?alt=media&token=4dc83a31-b8bd-4cca-9554-545076f8ea82",
      gif: "",
    },
  },

  Dominant_Motion: {
    name: "Dominant Motion",
    tool: ["adobe photoshop"],
    year: 201806,
    tag: ["music for all"],
    genre: ["game", "media art", "music instrument"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Dominant%20Motion%2Fthumbnail.jpg?alt=media&token=db69f059-5d94-4a78-a367-d3af98386439",
      gif: "",
    },
  },

  Concerts: {
    name: "Concerts",
    tool: ["adobe creative tools", "cubase", "p5.js", "python", "tensorflow"],
    year: 201806,
    tag: ["music for all", "interactions", "music with code"],
    genre: ["media art", "concert", "MIR", "metaverse"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Concerts%2Fthumbnail.jpg?alt=media&token=822d4f40-07c7-4660-9807-969dfd071f29",
      gif: "",
    },
  },

  Participated_Exhibitions: {
    name: "Participated Exhibitions",
    tool: ["adobe photoshop"],
    year: 201806,
    tag: ["music for all", "music with code", "interactions"],
    genre: ["media art", "music video"],
    thumbnail: {
      still:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Participated%20Exhibitions%2Fthumbnail.jpg?alt=media&token=5ebe0c2f-b202-4a1b-9090-91fda20ebc76",
      gif: "",
    },
  },
};

export default contents;
