import { React } from "react";


const Main = (props) => {   
    return (
        <div className = "main">
            <a href={"works/doongdoong_club"}>
                <div className="recent">
                    
                    <h1>Recent Works</h1>
                    <h2>DoongDoong.club</h2>
                    <div>Proceeding Paper, NIME 2024</div>
                    
                </div>
            </a>
        </div>
    );
};

export default Main;
