import cv from "./about.json";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function About(props) {
  // 상태 관리
  const [isExpanded, setIsExpanded] = useState(false);

  // "더보기"/"접기" 버튼 클릭 이벤트
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const category = {
    Education: 0,
    Research: 1,
    Exhibitions: 2,
    Awards: 3,
    Skills: 4,
  };

  return (
    <div className="about">
      <div className="intro">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/chaeryeongoh.jpg?alt=media&token=556a54f9-ace9-4136-888e-b236135c3e48"
          alt="Chaeryeong Oh"
        ></img>
        <div className="text">
          <h3>
            Musician, Programmer, Media Artist
            <br />
            and Enthusiastic Gamer.
          </h3>
          <p>I search for a way for everyone can enjoy music.</p>
          <p>
            I seek the possibility of music-full everyday life - using music for
            learning, expressing ideas, communication, and so on.
          </p>
        </div>
      </div>

      <div className="contents">
        <div className="bio">
          <p>
            Chaeryeong Oh is currently pursuing a master's program in music
            technology at Georgia Tech. They earned a B.A.S. in Art & Technology
            and a B.S. in Computer Science and Engineering from Sogang
            University. Chaeryeong explores Korean futurism and sustainability
            through interactive music. Their current research topic is shaping
            Korean queer futurism by connecting microbiology and ecology with
            their life to build an autoethnographic sound art. Their recent
            work, “Flowers For Everyone From Nowhere” is a web Vjing tool based
            on the imaginary flower garden Seocheon Kkotbat in Korean folktales
            and shamanism.
          </p>
          <div onClick={toggleExpand} style={{cursor:'default', fontWeight:'bold'}}>
          {isExpanded ? "View less" : "View old biography"}
        </div>
          {isExpanded && (
            <span>
              {" "}
              <p>
                Chaeryeong Oh is a graduate candidate with a Bachelor of Art and
                Science in Art & Technology and a Bachelor of Science in
                Computer Science and Engineering. They have focused on human
                interactions with and various expressions of music, such as
                using music as a medium to explain diverse topics, a
                straightforward method of shaping up ideas, a communication
                system, or exploring fun ways to teach the concept of music
                theory, under their basis of "Music for All" - easy and amusing
                access to music for everyone. They are currently interested in
                creating a generalized music framework to aid music creativity
                and draw entertaining musical interactions.{" "}
              </p>
              <p>
                Their work "Tonic Scape", a VR experience in which users can
                visually draw the melody, was awarded second place at the KT
                Super VR game challenge in 2020 and released as game content in
                KT Super VR. "Need For Sound", the experience of the
                possibilities of harmonic grammar, and "Doongdoong.club", the
                experience of using music as a communication tool, are presented
                in HCI Korea in 2019 and 2022.
              </p>
              <p>
                They also had an internship for the media artist group Seoul
                Open Media, whose leader is media artist Byungjun Kwon and
                enhanced their view of the fusion of Korean traditional culture
                and high technology. During the apprenticeship, they
                participated in "The Theater of the Future (미래극장)", held by
                Gyeonggi Sinawi Orchestra, a Korean traditional music group of
                Gyeonggi-do, Korea, by using deep learning to generate new sound
                with Korean folk music. In Contents Impact: Music Meets AI at
                Korea Creative Content Agency, they also had an experience using
                deep learning to generate neo-Korean traditional music and held
                a concert with the results.
              </p>
            </span>
          )}
        </div>
        <a href="https://www.dropbox.com/s/91g88m1qwhbnmk2/Chaeryeong%20Jade%20Oh.pdf?dl=0s">
          Offline CV
        </a>
        <br></br>
        SoundCloud: <a href="https://soundcloud.com/jade570">Jade570</a>
        <br></br>
        Github: <a href="https://github.com/Jade570">Jade570</a>
        <br></br>
        Email:{" "}
        <a href="mailto: hello@chaeryeongoh.com">hello@chaeryeongoh.com</a>
        <br />
        <br />
        <hr />
        {/* CV render from about.json */}
        <div className="CV">
          <br />
          {/* Education */}
          <h2>{Object.keys(cv[category.Education])}</h2>
          <div className="education">
            {cv[category.Education]["Education"].map((item) => {
              return (
                <div className="edu">
                  <div className="item">
                    <div className="year">{item.start}~</div>
                    <div className="content">{item.degree}</div>
                  </div>
                  <div className="item">
                    <div className="year">{item.end}</div>
                    <div className="content">{item.school}</div>
                  </div>
                  <div className="item">
                    <div className="year"></div>
                    <div className="content" style={{ fontStyle: "italic" }}>
                      {item.thesis}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <br />

          {/* Research Interests */}
          <h2>{Object.keys(cv[category.Research])}</h2>
          <div className="research interests">
            {cv[category.Research]["Research Interests"].map((item) => {
              return <div>{item}</div>;
            })}
          </div>
          <br />

          {/*Exhibitions / Performances / Publications*/}
          <h2>{Object.keys(cv[category.Exhibitions])}</h2>
          <div className="exhibitions">
            {cv[category.Exhibitions][
              "Exhibitions / Performances / Publications"
            ].map((item) => {
              return (
                <div className="item">
                  <div className="line">
                    <div className="year">{item.year}</div>
                    <div className="content">
                      <div className="title">
                        <div className="tag">[{item.tag}]</div>
                        <Link to={item.link}>
                          <div className="content">{item.content}</div>
                        </Link>
                      </div>

                      <div className="place">{item.place}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <br />

          {/*Awards*/}
          <h2>{Object.keys(cv[category.Awards])}</h2>
          <div className="awards">
            {cv[category.Awards]["Awards"].map((item) => {
              return (
                <div className="item">
                  <div className="year">{item.year}</div>
                  <div className="content">{item.content}</div>
                </div>
              );
            })}
          </div>
          <br />

          {/*Skills*/}
          <h2>{Object.keys(cv[category.Skills])}</h2>
          <div className="skills">
            {cv[category.Skills]["Skills"].map((item) => {
              return (
                <div className="item">
                  <div className="name">{item.name}</div>
                  <div className="content">{item.content}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
