import contents from "./contents";
import {useParams} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Sticky from 'react-sticky-el';
import Markdown from "markdown-to-jsx";

const Work = (props) => {
  const [post, setPost] = useState(""); // eslint-disable-next-line
  let params = useParams();
  const title = params.work;
  const info = contents[title];
  const year = info.year ? [info.year.toString().substr(0, 4)] : null;

  useEffect(() => {
    info.name = info.name.replace(/!/g, '');
    console.log("THIS IS THE NAME: ",`./md/${info.name}.md`);
    import(`./md/${info.name}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));// eslint-disable-next-line
  }, []);

  return (
    <div className="work">
        <Sticky stickyStyle={{margin:"0"}}>
            <div className="header">
              <div id="title">
                <h1 ref={props.header}>{info.name}</h1>
                <div className="year">
                {year}
                {info.genre
                  ? info.genre.map((x) => {
                      return `, ${x}`;
                    })
                  : {}}
                </div>
              </div>
              <div id="tag">
                {info.tag
                ? info.tag.map((item) => {
                    return <button>{item}</button>;
                  })
                : {}}
              </div>
            </div>
        </Sticky>

      <div className="contents">
        {info.vid ? (
          <div className="video-container">
            <object data={info.vid} aria-label="youtube video"></object>
          </div>
          ) : (
            <div></div>
        )}

        <Markdown className="md">{post}</Markdown>
      </div>
    </div>
  );
};

export default Work;
